



















import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import decamelize from 'decamelize';
import {
  BAvatar,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BMedia,
  BMediaAside, BMediaBody,
  BRow
} from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";

@Component({
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    StatisticCardVertical
  }
})
export default class MaintenanceDashboard extends Vue {
  decamelize(value, options) { return decamelize(value, options) }

  public counts: { [index: string]: number } | null = null;

  async mounted() {
    await this.getDashboardCounts();
  }
  async getDashboardCounts() {
    const gdprRequestCounts = await this.$api.maintenance.gdprrequests.Count();

    this.$set(this, "counts", {
      "GDPR Requests": gdprRequestCounts
    });
  }

  @Watch("$route.fullPath")
  async watchPath() {
    await this.getDashboardCounts();
  }
}
